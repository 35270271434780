.mainabout {
    border-radius: 10px;
    background: #050505;
    background-image: url('../../../src/aboutbg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 551px;
    width: 100%;
margin-bottom: 5px;
    .maincontent {
        padding-top: 105px;

        h6 {
            color: #008CF7;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            /* 22.4px */
            letter-spacing: 1.76px;
        }

        .whitehead {
            color: #FFF;

            font-family: "Chakra Petch";
            font-size: 50px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            /* 60px */
            text-transform: uppercase;
            margin: 25px 0px;

            span {
                color: #FFF;
                font-family: "Chakra Petch";
                font-size: 50px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                text-transform: uppercase;
            }
        }

        .holdpara {
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            /* 25.2px */
            letter-spacing: 0.54px;
            max-width: 605px;
            width: 100%;
            margin-bottom: 25px;
        }

        .whiteendpara {
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: 0.54px;
            max-width: 605px;
            width: 100%;

            span {
                color: #008CF7;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                /* 25.2px */
                letter-spacing: 0.54px;

            }
        }
    }
}

@media(max-width:991px) {
    .formobil {
        display: block !important;
        width: 375.406px;
        height: 375.957px;
        object-fit: cover;
        object-position: center;
        overflow: hidden;
margin-top: 30px;
        .innerimg {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .mainabout {
        background-image: none !important;
        padding-left: 18px;
        padding-right: 18px;
        height: auto;
    }

    .mainabout .maincontent {
        padding-top: 40px;
    }

    .mainabout .maincontent h6 {
        font-size: 14px;
    }
    .mainabout .maincontent .whitehead{
        font-size: 34px !important;
    }
    .mainabout .maincontent .whitehead span{
        font-size: 34px;
    }
    .mainabout .maincontent .parawhite{
        font-size: 16px;
    }
    .mainabout .maincontent .whiteendpara{
        font-size: 16px;
    }
    .mainabout .maincontent .whiteendpara span{
        font-size: 16px;
    }
    .mainabout .maincontent .holdpara{
        font-size: 16px;
    }
}