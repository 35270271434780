.ourwork {
    border-radius: 10px;
    background: #050505;
    background-image: url('../../../src/ourwork.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
margin-bottom: 5px;
    .parentcontent {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 259px;
        .left {
          
            .mainheading {
                h6 {

                    color: #008CF7;
                    margin-bottom: 10px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                    /* 22.4px */
                    letter-spacing: 1.76px;
                    text-transform: uppercase;
                }

                .maincrypto {
                    width: 166px;
                    height: 39.747px;
                    overflow: hidden;
                    object-fit: cover;
                    object-position: center;
                    margin-top: 45px;
                    margin-bottom: 25px;

                    .cryptoimg {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                h2 {
                    color: #FFF;
                    margin-bottom: 16px;
                    font-family: "Chakra Petch";
                    font-size: 50px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    white-space: nowrap;
                    /* 60px */
                    text-transform: uppercase
                }

                p {
                    color: #FFF;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    /* 25.2px */
                    letter-spacing: 0.54px;
                }
            }

            // .mainworkimg {
            //     // width: 563.36px;
            //     // height: 684.293px;
            //     object-fit: cover;
            //     object-position: center;
            //     overflow: hidden;

            //     .innerimg {
            //         width: 100%;
            //         height: 100%;
            //         object-fit: cover;
            //         object-position: center;
            //     }
            // }
        }
    }

}

@media(max-width:991px){
    .ourwork .parentcontent{
        flex-direction: column;
        gap: 55px;
        padding-top: 40px;
        align-items: flex-start;
        padding-left: 18px;
        padding-right: 18px;
    }
    .ourwork .parentcontent .left .mainheading h6{
        font-size: 14px;
    }
    .ourwork .parentcontent .left .mainheading p{
        font-size: 14px;
    }
 
}
@media(max-width:600px){
    .ourwork .parentcontent .left .mainheading h2{
        font-size: 34px !important;
    }
}