.mainfooter {
    padding: 50px 0px 20px;
background-image: url('../../../../src/footor.png');
object-fit: cover;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
border-radius: 10px;
    .innerfooter {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .footerleft {
            .logopara {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                margin-top: 38px;
                max-width: 290px;
                width: 100%;
            }
        }

        .footerright {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            gap: 90px;

            .footerlinks {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 10px;

                .footerlinkhead {
                    color: #FFF;
            
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: uppercase;
                }

                .footerlinkpara {
                    color: #8B8B8B;
            white-space: nowrap;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin: 0px;
                    text-decoration: none;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;
                    margin-bottom: 10px;
                }
                a {
                    color: #8B8B8B;
            white-space: nowrap;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin: 0px;
                    text-decoration: none;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;
                    margin-bottom: 10px;
                }
                .active-class{
                    color: #008CF7 !important;
                }
            }
        }
    }
}
.brdr{
    background: rgba(255, 255, 255, 0.10);
width: 100%;
height: 1px;
margin: 16px 0px;
}

.lastline {
    // padding: 16px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .lastlineparaleft {
        color: #9F9F9F;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;

        .bold {
            color: #FFF;
        }
    }

    .toppara {
        color: #008CF7;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 5px;
        cursor: pointer;
    }
}

@media(max-width:600px) {
    // .mainfooter .innerfooter .footerright .footerlinks .footerlinkhead{
    //     display: none;
    // }
    .mblbrdr{
        display: block !important;
        margin: 30px 0px;
        width: 384px;
        height: 1px;
        background: rgba(255, 255, 255, 0.10);
    }
    .mainfooter .innerfooter .footerleft{
        margin-bottom: 40px;
    }
    .mainfooter .innerfooter{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .footerleft{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
        }
        .footerright{
            width: 100%;
        }
    }
    .mainfooter .innerfooter .footerleft .logopara{
        max-width: 100%;
        text-align: center;
        margin-bottom: 40px;
    }
    .mainfooter .innerfooter .footerright{
        flex-direction: column;
        gap: 0px;
    }
    .mainfooter .innerfooter .footerright .footerlinks{
        justify-content: center;
        align-items: center;
        width: 100%;
        .innerfooterlinks{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            flex-wrap: wrap;
            gap: 20px;
            // padding-bottom: 30px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.10);
            // margin-bottom: 30px;
        }
    }
    .mainfooter{
        padding: 30px 0;
        border-bottom: 0px;
    }
    .mainfooter .innerfooter .footerright .footerlinks .innerfooterlinks{
        border-bottom: none;
    }
    .lastline .lastlineparaleft{
        font-size: 10px;
    }
    .FORMNNN{
        display: none !important;
    }
}
@media(max-width:425px){
    .mainfooter .innerfooter .footerright .footerlinks .footerlinkpara{
        font-size: 14px;
        white-space: nowrap;
    }
}