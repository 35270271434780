.contactmain {
    padding: 70px 0px;

    .innercontact {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.03);

        .contactleft {
            width: 100%;
            border-radius: 20px 0px 0px 20px;
            background: rgba(255, 255, 255, 0.06);
            padding: 70px 50px;

            .contactgreenpara {
                color: #31F2B3;
                font-family: 'Mada';
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
                margin-bottom: 20px;
                letter-spacing: 1.08px;
                text-transform: uppercase;
            }

            .contactmainhead {
                color: #FFF;
                margin-bottom: 20px;
                font-family: 'Mada';
                font-size: 50px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%;
                text-transform: uppercase;
            }

            .contactline {
                background: #31F2B3;
                width: 107px;
                height: 2px;
                display: block;
                margin-bottom: 30px;
            }

            .contactpara {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }

        }

        .contactright {
            width: 100%;
            padding: 40px 50px 46px 50px;

            .inputlabel {
                color: #FFF;
                font-family: 'Mada';
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                margin-bottom: 15px;
            }

            .contactinput {
                border-radius: 10px;
                background: rgba(255, 255, 255, 0.05);
                padding: 19px 25px;
                color: #FFF;
                font-family: 'Mada';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                outline: none;
                border: none;
                width: 100%;
                margin-bottom: 20px;
                border: 1px solid transparent;

                &:focus {
                    border: 1px solid #31F2B3;
                    transition: 0.7s;
                }

                &::placeholder {
                    color: rgba(255, 255, 255, 0.30);
                    font-family: 'Mada';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                }
            }

            .submitbtn {
                border-radius: 15px;
                background: #31F2B3;
                color: #0F503B;
                text-align: center;
                font-family: 'Mada';
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
                text-transform: uppercase;
                padding: 20px 44px;
                border: none;
                outline: none;
                width: 100%;
                margin-top: 25px;

                &:hover {
                    transition: 0.7s;
                    background: #24D69C;
                }
            }
        }
    }
}

@media(max-width:768px) {
    .contactmain .innercontact {
        grid-template-columns: 1fr;
    }

    .contactmain .innercontact .contactleft {
        border-radius: 20px 20px 0px 0px;
    }
}

@media(max-width:600px) {
    .contactmain .innercontact .contactleft {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 40px 25px;
    }

    .contactmain .innercontact .contactleft .contactgreenpara {
        font-size: 14px;
    }

    .contactmain .innercontact .contactleft .contactmainhead {
        font-size: 35px;
    }

    .contactmain .innercontact .contactleft .contactpara {
        font-size: 14px;
        text-align: center;
    }

    .contactmain .innercontact .contactright {
        padding: 30px 25px 40px 25px;
    }
}

.modal-backdrop{
    background: rgba(0, 0, 0, 0.40);
    backdrop-filter: blur(14px);
    opacity: unset;
}

.modal-backdrop.fade{
    opacity: unset;
}

.thankyoumodal {
    .modal-dialog {
        max-width: 495px;
        .modal-content {
            border-radius: 15px;
            background: #0D0D31;
            border: none;
            padding: 5px 5px 30px 5px;

            .modal-header {
                border: none;
                border-radius: 7px;
                background: #0E0E3A;
                padding: 15px;

                .modal-title {
                    color: #FFF;
                    font-family: 'Mada';
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    text-transform: capitalize;
                }

                .btn-close {
                    width: 24px;
                    height: 24px;
                    outline: none;
                    border: none;
                    opacity: unset;
                    box-shadow: none;
                    padding: 0px;
                    background: url('../../../assets/close-circle.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    margin: 0px;
                }
            }

            .modal-body {
                padding: 0px;

                .innerthankyou {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    .thankyouimg {
                        width: 88px;
                        height: 89px;
                        margin: 24px 0px;
                    }

                    .thankyouhead{
                        color: #FFF;
                        text-align: center;
                        font-family: 'Mada';
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 140%; 
                        text-transform: capitalize;
                        margin-bottom: 8px;
                    }

                    .thankyoupara{
                        color: #FFF;
                        text-align: center;
                        font-family: 'Mada';
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                    }
                }
            }
        }
    }
}

@media(max-width:425px){
    .thankyoumodal .modal-dialog .modal-content .modal-body .innerthankyou .thankyouhead{
        font-size: 16px;
    }
    .thankyoumodal .modal-dialog .modal-content .modal-body .innerthankyou .thankyoupara{
        font-size: 14px;
    }
}