.mainget {
    border-radius: 10px;
    background: #050505;
    margin-bottom: 5px;

    .parent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 105px 0px;

        .left {
            .mainheading {
                h6 {
                    color: #008CF7;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                    /* 22.4px */
                    letter-spacing: 1.76px;
                    margin-bottom: 10px;
                }

                h2 {
                    color: #FFF;

                    font-family: "Chakra Petch";
                    font-size: 50px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    /* 60px */
                    text-transform: uppercase;
                    margin: 25px 0px;
                }

                p {
                    color: #FFF;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    /* 25.2px */
                    letter-spacing: 0.54px;
                    max-width: 516px;
                    width: 100%;
                }
            }

        }

        .right {
            .parentbox {
                border-radius: 10px;
                border: 1px solid rgba(255, 255, 255, 0.10);
                display: flex;
                align-items: center;
                justify-content: center;

                .box {
                    border: 0px solid rgba(255, 255, 255, 0.10);
                    padding: 25px 20px;
                    width: 100%;
                    min-width: 315px;

                    p {
                        color: #FFF;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                        /* 25.2px */
                        letter-spacing: 0.54px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 17px;
                    }
                }

                .box1 {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
                    border-left: 1px solid rgba(255, 255, 255, 0.10);
                    padding: 25px 20px;
                    width: 100%;
                    min-width: 315px;

                    p {
                        color: #FFF;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                        /* 25.2px */
                        letter-spacing: 0.54px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 17px;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}

@media(max-width:600px) {
    .mainget .parent {
        flex-direction: column;
        gap: 50px;
        padding-left: 18px;
        padding-right: 18px;
    }

    .mainget .parent .right .parentbox {
        flex-direction: column;
    }

    .mainget .parent .right {
        width: 100%;
    }

    .mainget .parent .right .parentbox .box {
        border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    }

    .mainget .parent .left .mainheading h2 {
        font-size: 34px;
    }

    .mainget .parent .left .mainheading p {
        font-size: 16px;
    }

    .mainget .parent .right .parentbox .box {
        min-width: 100%;
    }

    .mainget .parent .right .parentbox .box1 {
        min-width: 100%;
    }

    .mainget .parent .right .parentbox .box p {
        font-size: 16px;
    }
}