.mainbanner {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 10px;
    background: #050505;
    margin-bottom: 5px;
    overflow: hidden;
    .topshade{
        background: rgba(5, 5, 5, 0.90);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .main-banner-video{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
        object-position: center;
    }
    .innerbanner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 99;
        position: relative;
        margin-top: -15px;

        .mainbannerhead {
            color: #FFF;

            text-align: center;
            font-family: "Chakra Petch";
            font-size: 70px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
        
            text-transform: uppercase;
         
        }

        .boldhead {
            color: #FFF;
            font-family: "Chakra Petch";
            font-size: 70px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
        }

        .mainbannerpara {
            color: #FFF;

            text-align: center;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            /* 28.6px */
            letter-spacing: 0.66px;
            // max-width: 770px;
            // width: 100%;
            margin-bottom: 50px;
            margin-top: 25px;
        }

        .bannerbtns {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;

            .bluebtn {
                border-radius: 50px;
                background: #008CF7;
                padding: 20px 44px;
                width: 100%;
                color: #FFF;

                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
                /* 16px */
                text-transform: uppercase;
                border: none;
            }

            .greenbtn {
                display: flex;
                padding: 20px 0px;
                width: 165px;
                justify-content: center;
                align-items: center;
                color: #114B38;
                text-align: center;
                font-family: 'Mada';
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
                text-transform: uppercase;
                border: none;
                outline: none;
                border-radius: 15px;
                background: #31F2B3;

                &:hover {
                    transition: 0.7s;
                    background: #24D69C;
                }
            }

            .dullbtn {
                border-radius: 15px;
                background: #0D0D31;
                display: flex;
                padding: 20px 0px;
                width: 165px;
                justify-content: center;
                align-items: center;
                border: none;
                outline: none;
                color: #FFF;
                text-align: center;
                font-family: 'Mada';
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
                text-transform: uppercase;

                &:hover {
                    transition: 0.7s;
                    background: #19194A;
                }
            }
        }
    }
}

@media(max-width:600px) {
    .mainbanner .innerbanner .mainbannerhead {
        font-size: 41px;
    }
    .mainbanner{
        height: 730px !important;
        min-height: unset;
    }

    .mainbanner .innerbanner .mainbannerpara {
        font-size: 18px;
        width: 384px;
    }
    
.mainbanner .innerbanner .mainbannerhead {
    font-size: 38px !important;
}
.mainbanner .innerbanner .boldhead{
    font-size: 38px !important;
}
.mainbanner .innerbanner .bannerbtns .bluebtn{
    font-size: 14px;
}
}

@media(max-width:425px) {
    // .mainbanner .innerbanner .mainbannerhead {
    //     font-size: 37px ;
    // }
}