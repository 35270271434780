.mainspeciality {
    padding: 70px 0px;

    .specialhead {
        color: #FFF;
        font-family: 'Mada';
        font-size: 45px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .specialpara {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        text-align: center;
        margin-bottom: 50px;
    }

    .innerspaeciality {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;

        .specialcard {
            border-radius: 20px;
            background: rgba(255, 255, 255, 0.03);
            padding: 40px;
            cursor: pointer;

            &:hover {
                background: rgba(255, 255, 255, 0.06);
                transition: 0.7s;

                .specialcardimg {
                    background: #31F2B3;
                    transition: 0.7s;

                    path {
                        fill: #114B38;
                        stroke: #114B38;
                        transition: 0.7s;
                    }
                }
            }

            .specialcardimg {
                border-radius: 10px;
                background: rgba(49, 242, 179, 0.10);
                display: flex;
                width: 100px;
                height: 100px;
                justify-content: center;
                align-items: center;
                margin-bottom: 30px;

                path {
                    fill: #31F2B3;
                    stroke: #31F2B3;
                }
            }

            .specialcardhead {
                color: #FFF;
                font-family: 'Mada';
                font-size: 26px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
                margin-bottom: 25px;
            }

            .specialcardpara {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                margin-bottom: 40px;
            }

            .viewmorelink {
                color: #31F2B3;
                font-family: 'Mada';
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;
            }
        }
    }
}

@media(max-width:600px) {
    .mainspeciality{
        padding: 32px 0px 75px 0px;
    }
    .mainspeciality .innerspaeciality {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .mainspeciality .specialhead {
        font-size: 35px;
    }

    .mainspeciality .specialpara {
        font-size: 16px;
    }

    .mainspeciality .innerspaeciality .specialcard {
        padding: 30px;
    }

    .mainspeciality .innerspaeciality .specialcard .specialcardimg {
        width: 80px;
        height: 80px;

        .innerspecialcardimg {
            width: 48px;
            height: 48px;
        }
    }

    .mainspeciality .innerspaeciality .specialcard .specialcardpara {
        font-size: 16px;
    }
}