.mainchoose {
    padding: 70px 0px;
    background-image: url('../../../../src/bgbg.png') !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    border-radius: 10px;
    background: #050505;
    margin-bottom: 5px;
    .parent {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .choosehead {
            color: #008CF7;

            font-family: Archivo;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            /* 22.4px */
            letter-spacing: 1.76px;
            text-transform: uppercase;
            margin-bottom: 10px;
            text-align: center;
            text-transform: uppercase;
        }

        .choosepara {
            color: #FFF;

            font-family: "Chakra Petch";
            font-size: 50px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            /* 60px */
            text-transform: uppercase;
            margin-top: 25px;

            span {
                color: #FFF;

                font-family: "Chakra Petch";
                font-size: 50px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                /* 60px */
                text-transform: uppercase;
            }
        }
    }

    .cardparent {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 60px;
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.10);

        .firstcard {
            border: 0px solid rgba(255, 255, 255, 0.10);
            background: transparent;
            padding: 25px 20px;
            width: 100%;

            .upperimg {
                width: 50px;
                height: 50px;
                object-fit: cover;
                object-position: center;
                overflow: hidden;

                .imgimg {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            h6 {
                color: #FFF;

                font-family: "Chakra Petch";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
                /* 16px */
                text-transform: uppercase;
                margin-top: 30px;
                margin-bottom: 16px;
            }

            p {
                color: #B7B7B7;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }
        }

        .secondcard {
            border-left: 1px solid rgba(255, 255, 255, 0.10);
            background: transparent;
            padding: 25px 20px;
            width: 100%;

            .upperimg {
                width: 50px;
                height: 50px;
                object-fit: cover;
                object-position: center;
                overflow: hidden;

                .imgimg {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            h6 {
                color: #FFF;
white-space: nowrap;
                font-family: "Chakra Petch";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
                /* 16px */
                text-transform: uppercase;
                margin-top: 30px;
                margin-bottom: 16px;
            }

            p {
                color: #B7B7B7;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }
        }

        .lastcard {
            // border-bottom: 1px solid rgba(255, 255, 255, 0.10);
            border-left: 1px solid rgba(255, 255, 255, 0.10);
            background: transparent;
            padding: 25px 20px;
            width: 100%;

            .upperimg {
                width: 50px;
                height: 50px;
                object-fit: cover;
                object-position: center;
                overflow: hidden;

                .imgimg {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            h6 {
                color: #FFF;

                font-family: "Chakra Petch";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
                /* 16px */
                text-transform: uppercase;
                margin-top: 30px;
                margin-bottom: 16px;
            }

            p {
                color: #B7B7B7;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }
        }
    }


}

@media(max-width:992px) {
    .mainchoose .innerchoose .choosecard {
        min-width: 285px;
    }

    .mainchoose .innerchoose {
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}
@media(max-width:991px) {
    .mainchoose {
        padding: 32px 0px;
    }

 
    .mainchoose .parent .choosehead{
font-size: 14px;
    }
    .mainchoose .parent .choosepara{
        font-size: 34px;
    }
    .mainchoose .parent .choosepara span{
        font-size: 34px;
    }
    .mainchoose .cardparent{
        flex-direction: column;
    }
    .mainchoose .cardparent .firstcard{
        border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    }
    .mainchoose .cardparent .secondcard{
        border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    }
    .mainchoose .cardparent .lastcard{
        border: 0px solid rgba(255, 255, 255, 0.10);
    }
}
@media(max-width:600px) {
    .mainchoose {
        padding: 32px 0px;
    }

 
    .mainchoose .parent .choosehead{
font-size: 14px;
    }
    .mainchoose .parent .choosepara{
        font-size: 34px;
    }
    .mainchoose .parent .choosepara span{
        font-size: 34px;
    }
    .mainchoose .cardparent{
        flex-direction: column;
    }
    .mainchoose .cardparent .firstcard{
        border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    }
    .mainchoose .cardparent .secondcard{
        border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    }
    .mainchoose .cardparent .lastcard{
        border: 0px solid rgba(255, 255, 255, 0.10);
    }
}