.terms {
    padding-top: 184px;
    padding-bottom: 70px;
    .maincontent {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      max-width: 1024px;
      width: 100%;
      margin: 0 auto;
      .heads {
        color: #FFF;
        font-family: "Chakra Petch";
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; 
        text-transform: uppercase;
        margin-bottom: 29px;
      }
      .headsinner {
        color: #FFF;
        font-family: "Chakra Petch";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        margin-bottom: 29px;
        text-transform: uppercase;
      }
      .para {
        color: #9B9B9B;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        margin-bottom: 29px;
        span {
          font-weight: bold;
        }
        a {
          color: #4dd388 !important;
        }
      }
    }
  }

  @media(max-width:600px){
    .terms{
        padding-top: 121px;
    }
    .terms .maincontent .heads{
        font-size: 38px;
    }
    .terms .maincontent .para{
        font-size: 16px;
    }
  }