.main-navbar {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(32px);
    z-index: 999;
    width: 100%;
    .navbar {
        padding: 13px 0px;


        .navbar-brand {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px;
            padding: 0px;
        }

        .navbar-collapse {
            .tusfssfsuxfufsu {

                .btn-common {
                    border-radius: 50px;
                    border: 1px solid #008CF7 !important;
                    background:transparent;
                    display: flex;
                    padding: 15px 25px;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    text-align: center;
                   
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    text-transform: uppercase;
                    border: none;
                    outline: none;
                }
            }

            .navbar-nav {
                margin: 0 auto;
                gap: 40px;

                .nav-item {
                    position: relative;
                    z-index: 2;
                    padding: 0px;

                    .nav-link {
                        color: #FFF;
                        text-align: center;
                       
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        padding: 0px;
                    }
                }
            }
        }
    }
}
.navtop{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 10px;
    z-index: 99;
}
.navbar-toggler:focus {
    box-shadow: none;
}

@media (max-width:1250px) {
    .main-navbar .navbar .navbar-brand p {
        font-size: 15px;
    }
}


@media (max-width:991px) {
    .main-navbar .navbar-collapse {
        background-color: #01060E;
        border-radius: 7px;
        padding-bottom: 20px;

    }



    .main-navbar .navbar .navbar-collapse .navbar-nav {
        justify-content: center;
        align-items: center;
    }

    .main-navbar .navbar .navbar-collapse form {
        justify-content: center;
        align-items: center;
    }

    .main-navbar .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
        padding: 15px 0;
    }
}

@media (max-width:600px) {
    .main-navbar .navbar-collapse {
        padding: 40px 20px;
    }

    .main-navbar .navbar .navbar-collapse form {
        flex-direction: column;
        gap: 20px;
    }

    .main-navbar .navbar .navbar-collapse form a {
        margin: 0 auto !important;
        width: 90%;
    }

    .audit-btn {
        display: block !important;
        position: absolute;
        top: 17px;
        right: 56px;
        z-index: 9;
    }



    .mobile-none {
        display: none !important;
    }

    .main-navbar .navbar .navbar-brand p {
        font-size: 16px;
        margin-top: 6px;
    }
}

.offcanvas.offcanvas-top {
    height: 100vh;
    background: #050505;

    .offcanvas-header .btn-close {
        background: url('../../../assets/close.svg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        padding: 0px;
        margin: 0px;
        opacity: unset !important;
    }

    .offcanvas-header {
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.03);
        backdrop-filter: blur(32px);
        padding: 16px 15px; 
        margin: 10px;
    }

    .nav-link {
        color: #FFF;
        width: 100%;
        text-align: center;
       
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-bottom: 1px solid #1B1B3C;
        padding: 20px 0px;
    }

    .navbar-brand {
        font-family: 'Slackey';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 31px;
        background: linear-gradient(90deg, #129DFF -3.02%, #56D054 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    .endbtn{
        padding: 20px;
        .blue{
            border-radius: 50px;
    border: 1px solid #008CF7;
    padding: 20px 25px;
    width: 100%;
    color: #FFF;
    background: transparent;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
        }
    }
    .offcanvas-body {
        overflow: hidden;
        padding: 0px;
        position: relative;

        .offlast {
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translate(-50%, -20px);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 33px;

            .socials {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                .scoialpara {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    color: #8B8B8B;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .offlastpara {
                color: #9F9F9F;
               
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
            }
        }
    }

}

.offcanvas-header .btn-close:focus {
    border: none;
    box-shadow: none;
    outline: none;
}